.akademik-content-container .accordion-item {
    border: none;
}

.akademik-content-container .accordion-item .accordion-button {
    background-color: transparent;
}

.akademik-content-container .accordion-item .accordion-button {
    font-family: Roboto;
    font-weight: normal;
    font-size: 25px;
    color: #000;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.akademik-content-container .accordion-item .accordion-button:not(.collapsed) {
    font-weight: 500;
    font-size: 25px;
    color: #a83135;
}

.akademik-content-container .accordion-item .accordion-button::after {
    margin-left: 10px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4NCiAgPGcgaWQ9ImFycm93LWxlZnQtcy1saW5lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDI0KSByb3RhdGUoLTkwKSI+DQogICAgPHBhdGggaWQ9IlBhdGhfOTg5IiBkYXRhLW5hbWU9IlBhdGggOTg5IiBkPSJNMCwyNEgyNFYwSDBaIiBmaWxsPSJub25lIi8+DQogICAgPHBhdGggaWQ9IlBhdGhfOTkwIiBkYXRhLW5hbWU9IlBhdGggOTkwIiBkPSJNMi44MjgsNi4zNjRsNC45NS00Ljk1TDYuMzY0LDAsMCw2LjM2NGw2LjM2NCw2LjM2NCwxLjQxNC0xLjQxNFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDggNS42MzYpIi8+DQogIDwvZz4NCjwvc3ZnPg0K')
}

.akademik-content-container .accordion-item .accordion-header.hide-arrow .accordion-button::after {
    display: none;
}

.akademik-content-container .accordion-item .accordion-button:not(.collapsed)::after {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4NCiAgPGcgaWQ9ImFycm93LWxlZnQtcy1saW5lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDI0KSByb3RhdGUoLTkwKSI+DQogICAgPHBhdGggaWQ9IlBhdGhfOTg5IiBkYXRhLW5hbWU9IlBhdGggOTg5IiBkPSJNMCwwSDI0VjI0SDBaIiBmaWxsPSJub25lIi8+DQogICAgPHBhdGggaWQ9IlBhdGhfOTkwIiBkYXRhLW5hbWU9IlBhdGggOTkwIiBkPSJNMi44MjgsNi4zNjRsNC45NSw0Ljk1TDYuMzY0LDEyLjcyOCwwLDYuMzY0LDYuMzY0LDAsNy43NzgsMS40MTRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4IDUuNjM2KSIgZmlsbD0iI2E4MzEzNSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==')
}

.akademik-content-container .accordion-item .accordion-body {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.akademik-content-container .accordion-item .accordion-body button {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    transition: 0.3s all;
    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    text-align: left;
}

.akademik-content-container .accordion-item .accordion-body button:hover {
    color: #a83135;
}

.akademik-content-container .active-program .faculty-card {
    cursor: pointer;
}

.akademik-content-container .active-program .faculty-card img {
    width: 100%;
    margin-bottom: 10px;
}

.akademik-content-container .active-program .faculty-card p {
    font-size: 14px;
    color: #000;
}
.title-page-container .custom-border-top {
    background-color: #a83135;
    height: 3px;
    width: 55px;
    border-radius: 20px;
    border-top: none
}

.title-page-container p {
    font-family: Montserrat;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #a83135;
}

/* Min width 768 */
@media only screen and (min-width: 768px) {
    .title-page-container p {
        font-size: 30px;
    }
}
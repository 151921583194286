.news-detail-content-container .news-detail h1 {
    font-weight: bold;
    font-size: 40px;
    color: #000;
}

.news-detail-content-container .news-detail .toolbar img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.news-detail-content-container .news-detail .toolbar p {
    font-size: 20px;
    color: #000;
}

.news-detail-content-container .news-detail .toolbar p {
    margin-bottom: 0px;
}

.news-detail-content-container .news-detail .news-content-image {
    float: left;
    margin-bottom: 20px;
    width: 100%;
}

.news-detail-content-container .news-detail .news-contents {
    font-size: 18px;
    line-height: 30px;
    color: #000;
}

.news-detail-content-container .more-news {
    font-weight: bold;
    font-size: 30px;
    color: #000;
}

/* Max width 768px */
@media only screen and (min-width: 768px) {
    .news-detail-content-container .news-detail .news-content-image {
        margin-right: 20px;
        width: 50%;
    }
}
.jumbotron-container {
    position: relative;
}

.jumbotron-container .carousel-item .jumbotron-carousel-slide {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.jumbotron-container .carousel-item .carousel-caption {
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
}

.jumbotron-container .carousel-indicators {
    /* display: flex;
    align-items: center;
    justify-content: flex-start; */
}

.jumbotron-container .carousel-indicators button {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 2px solid transparent;
    transition: 0.15s all;
    opacity: 1;
    margin: 0px 20px;
}

/* .jumbotron-container .carousel-indicators button:after {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0px;
    height: 1px;
    width: 85px;
    background-color: white;
} */

.jumbotron-container .carousel-indicators button:hover {
    background-color: #FFF000;
    outline: 1px solid #FFF000 !important;
}

.jumbotron-container .carousel-indicators .active {
    background-color: #FFF000;
    outline: 1px solid #FFF000 !important;
}

.jumbotron-container .carousel-caption .custom-border-top {
    background-color: #fff;
    height: 5px;
    width: 64px;
    border-radius: 20px;
    border-top: none
}

.jumbotron-container .carousel-caption .title {
    font-weight: bold;
    font-size: 40px;
    color: #fff;
    margin-bottom: 0px;
    text-align: left;
}

.jumbotron-container .carousel-caption .subtitle {
    font-size: 14px;
    color: #fff;
    margin-bottom: 0px;
    text-align: left;
}

.jumbotron-container .carousel-caption .subtitle.bottom-style {
    font-size: 30px;
    font-family: Montserrat;
    font-weight: bold;
    color: #fff;
}

/** SOCIAL MEDIA **/
.jumbotron-container .social-media-container {
    position: absolute;
    bottom: 0;
    display: none;
}

.jumbotron-container .social-media-container .social-media-list {
    display: flex;
    flex-direction: column;
}

.jumbotron-container .social-media-container .social-media-list a {
    color: white;
    border: 1px white solid;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.jumbotron-container .social-media-container .social-media-list a svg {
    font-size: 16px;
}

.jumbotron-container .stepper-container {
    position: absolute;
    background: rgba(255, 255, 255, 0.67);
    border: 1px solid #707070;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 100px 0px 30px;
}

.jumbotron-container .stepper-container .stepper {
    padding: 10px 30px;
    width: 300px;
    display: none;
}

.jumbotron-container .stepper-container .stepper .title {
    font-weight: bold;
    font-size: 20px;
    color: #000;
}

.jumbotron-container .stepper-container .stepper .description {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    margin-bottom: 0;
    line-height: 1.3;
}

.jumbotron-container .stepper-container .stepper .date {
    font-size: 14px;
    line-height: 30px;
    color: #fff;
}

.jumbotron-container .stepper-container .stepper a {
    font-size: 14px;
    color: #a83135;
}

.jumbotron-container .stepper-container .more-news {
    background-color: #A83135;
    position: absolute;
    right: 0;
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jumbotron-container .stepper-container .more-news img {
    width: 24px;
    height: 24px;
}

/* Max width 576px */
@media only screen and (min-width: 576px) {
    .jumbotron-container .social-media-container {
        display: block;
    }
}

/* Min width 960 */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .jumbotron-container .carousel-caption .title {
        font-size: 60px;
        line-height: 60px;
    }

    .jumbotron-container .carousel-caption .subtitle {
        font-size: 30px;
        line-height: 40px;
    }
}

/* Min width 960 */
@media only screen and (max-width: 743px) {

    .jumbotron-container .stepper:first-child {
        display: block;
    }
}

/* Min width 960 */
@media only screen and (min-width: 743px) and (max-width: 1019px) {

    .jumbotron-container .stepper:first-child,
    .jumbotron-container .stepper:nth-child(2) {
        display: block;
    }
}

/* Min width 960 */
@media only screen and (min-width: 1019px) and (max-width: 1377px) {

    .jumbotron-container .stepper:first-child,
    .jumbotron-container .stepper:nth-child(2),
    .jumbotron-container .stepper:nth-child(3) {
        display: block;
    }
}

/* Min width 960 */
@media only screen and (min-width: 1377px) {
    .jumbotron-container .stepper {
        display: block !important;
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) {
    .jumbotron-container .carousel-caption .custom-border-top {
        height: 10px;
        width: 165px;
    }

    .jumbotron-container .carousel-caption .title {
        font-size: 80px;
        line-height: 100px;
    }

    .jumbotron-container .carousel-caption .subtitle {
        font-size: 24px;
        line-height: 30px;
    }

    .jumbotron-container .carousel-caption .subtitle.bottom-style {
        font-size: 50px;
    }
}
.home-content-container .profile img {
    width: 100%;
    object-fit: cover;
}

.home-content-container .agenda .agenda-card {
    background: #f3f3f3;
}

.home-content-container .agenda .agenda-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-content-container .agenda .agenda-card .date {
    font-size: 10px;
    line-height: 30px;
    color: #b0abab;
}

.home-content-container .agenda .agenda-card .title {
    font-weight: 500;
    font-size: 25px;
    color: #000;
}


.home-content-container .agenda .carousel-indicators {
    bottom: -50px;
}

.home-content-container .agenda .carousel-indicators button {
    border-radius: 8px;
    width: 15px;
    height: 10px;
    transition: 0.15s all;
    opacity: 1;
    background-color: #B0ABAB;
    border: none;
}

.home-content-container .agenda .carousel-indicators button:hover {
    background-color: #FFF000;
}

.home-content-container .agenda .carousel-indicators .active {
    background-color: #FFF000;
    width: 30px;
}

.home-content-container .akademik .akademik-box {
    height: 123px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    color: #a83135;
    transition: 0.3s all;
    text-align: center;
}

.home-content-container .akademik .owl-theme .owl-item {
    padding: 20px 0px;
}

.home-content-container .akademik .owl-theme .owl-item.center .akademik-box,
.home-content-container .akademik .akademik-box.active {
    /* background: #a83135;
    color: #fff; */
}

.home-content-container .pimpinan-universitas .owl-theme .owl-item .lecturer-name {
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    color: #000;
    margin-bottom: 0;
    margin-top: 10px;
}

.home-content-container .pimpinan-universitas .owl-theme .owl-item .lecturer-job {
    font-size: 10px;
    text-align: center;
    color: #000;
    margin-bottom: 0;
}

.home-content-container .pimpinan-universitas .owl-theme .owl-item img {
    height: 300px;
    max-width: 200px;
}

.home-content-container .what-they-say .user-review-container .quote-box {
    background: #f3f3f3;
    border-radius: 30px;
    background: #f3f3f3;
    padding: 30px;
}

.home-content-container .what-they-say .item .user-review-container .quote-box img {
    width: 40px;
    height: 40px;
    background: transparent;
    margin-bottom: 10px;
}

.home-content-container .what-they-say .item .user-review-container .quote-box p {
    font-size: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #000;
    opacity: 0.6;
}

.home-content-container .what-they-say .item .user-review-container .triangle-comment {
    height: 20px;
}

.home-content-container .what-they-say .item .user-review-container .profile-container {
    margin-top: 20px;
}

.home-content-container .what-they-say .item .user-review-container .profile-container img {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    margin-bottom: 20px;
}

.home-content-container .what-they-say .item .user-review-container .profile-container h5 {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #000;
    opacity: 0.87;
    margin-bottom: 5px;
}

.home-content-container .what-they-say .item .user-review-container .profile-container h6 {
    font-family: Roboto;
    font-weight: normal;
    font-size: 10px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #000;
    opacity: 0.6;
    margin-bottom: 0;
}

.home-content-container .what-they-say .owl-carousel .owl-nav {
    display: block !important;
    margin-top: 0;
}

.home-content-container .what-they-say .owl-carousel .owl-prev,
.home-content-container .what-they-say .owl-carousel .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 65px;
    height: 65px;
    background: transparent;
}

.home-content-container .what-they-say .owl-carousel .owl-prev {
    left: -2rem;
}

.home-content-container .what-they-say .owl-carousel .owl-next {
    right: -2rem;
}

.home-content-container .kelas-digital .content {
    padding: 30px 0px;
}

.home-content-container .kelas-digital .content img {
    width: 100%;
}

.home-content-container .kelas-digital .content p,
.home-content-container .kelas-digital .content ul {
    font-weight: normal;
    font-size: 15px;
    color: #000;
}

/* Min width 768 */
@media only screen and (min-width: 768px) {
    .home-content-container .agenda .agenda-card .date {
        font-size: 16px;
    }

    .home-content-container .agenda .agenda-card .title {
        font-size: 30px;
    }

    .home-content-container .akademik .akademik-box {
        font-size: 15px;
    }

    .home-content-container .what-they-say .item .user-review-container .triangle-comment {
        height: 35px;
    }

    .home-content-container .what-they-say .item .user-review-container .profile-container img {
        height: 125px;
        width: 125px;
    }

    .home-content-container .what-they-say .item .user-review-container .profile-container h5 {
        font-size: 25px;
    }

    .home-content-container .what-they-say .item .user-review-container .profile-container h6 {
        font-size: 20px;
    }

    .home-content-container .kelas-digital .content {
        background: #eee7e7;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-content-container .akademik .akademik-box {
        font-size: 10px;
    }
}
* {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:root {
    --color-primary: #F6CD3C;
    --color-primary-hover: #f8da6d;
    --color-secondary: #961A1D;
    --color-secondary-hover: #cb8d8e;
    --color-white: #FFFFFF;
    --color-off-white: #F8F8F8;
    --color-grey: #ECECEC;
    --color-black: #070707;
}

html,
body,
#root {
    font-family: Roboto;
}

body {
    background-color: var(--color-off-white);
    overflow: hidden;
}

#root {
    overflow: auto;
    height: 100vh;
    width: 100vw;
}

.bg-primary-color {
    background-color: #88181C !important;
}

.read-more {
    font-size: 12px;
    color: #a83135;
}

.info-banner-navbar {
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    z-index: 999;
}

.info-banner-navbar .navbar-brand,
.info-banner-navbar .navbar-brand a {
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: underline;
    font-size: 14px;
    color: #fff000;
    transition: 0.3s all;
    display: none;
}

.info-banner-navbar .navbar-brand svg {
    font-size: 16px;
    margin-right: 5px;
}

.info-banner-navbar .navbar-nav .nav-link {
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: white !important;
    transition: 0.3s all;
}

.unes-navbar {
    margin-top: 44px;
    transition: 0.3s all;
}

.unes-navbar.scroll {
    background-color: rgba(0, 0, 0, 0.5) !important;
    margin-top: 0px;
}

.unes-navbar .navbar-nav .nav-link {
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: white !important;
    text-transform: uppercase;
    transition: 0.3s all;
    border-bottom: 3px transparent solid;
    margin: 0px 5px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.unes-navbar .navbar-nav .nav-link:hover {
    color: #fff000 !important;
    border-color: #fff000;
}

.unes-navbar .navbar-nav .nav-link.active {
    color: #fff000 !important;
    border-color: #fff000;
}

.unes-navbar .navbar-brand img {
    width: 100px;
    height: 100px;
}

.unes-navbar .navbar-toggler {
    border: none !important;
    color: transparent !important;
}

.unes-navbar .navbar-toggler svg {
    color: white !important;
}

.unes-btn {
    border-radius: 35px !important;
    background-color: #A83135 !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    box-shadow: none !important;
    border-color: transparent !important;
    transition: 0.3s all;
    padding-left: 30px !important;
    padding-right: 30px !important;
    width: max-content;
    height: max-content;
}

.unes-btn:hover {
    border-radius: 35px !important;
    background-color: #CB4D51 !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.unes-btn:active,
.unes-btn.focus {
    background-color: #8A282B !important;
}

.footer {
    background: #88181C;
    height: auto;
    padding-bottom: 30px;
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-top: 40px;
}

.footer p {
    margin: 0;
}

.footer img {
    width: 150px;
    height: 150px;
}

.footer h3 {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
}

.footer ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 15px;
}

.footer ul li a {
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    opacity: 0.37;
    text-decoration: none;
}

.footer .university-detail h3 {
    font-family: Bahnschrift;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 35px;
    color: #fff;
    margin: 0;
    padding: 0;
}

.footer .university-detail p {
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    color: #fff;
    margin-bottom: 20px;
}

.footer .university-detail svg {
    color: #fff;
    margin-right: 5px;
}

.footer .university-detail a {
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #fff;
    opacity: 1;
}

.footer-bottom {
    background: #750B0F;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer-bottom p {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
}

/* Max Width 350px */
@media only screen and (max-width: 350px) {}

/* Max width 576px */
@media only screen and (min-width: 576px) {

    .info-banner-navbar .navbar-brand,
    .info-banner-navbar .navbar-brand a {
        display: block;
    }

    .footer-bottom .univ-copyright {
        text-align: left !important;
    }

    .footer-bottom .rule-of-use {
        text-align: right !important;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {}

/* Max width 960px */
@media only screen and (max-width: 960px) {}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .info-banner-navbar .navbar-brand a {
        font-size: 12px;
    }

    .info-banner-navbar .navbar-nav .nav-link {
        font-size: 12px;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {}

/* Min width 960 */
@media only screen and (min-width: 960px) {}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {}

/* Min width 1550px */
@media only screen and (min-width: 1550px) {}
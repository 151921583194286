.content-container .title {
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    color: #000;
}

.content-container .description {
    font-size: 14px;
    color: #000;
}

.content-container .read-more {
    font-size: 12px;
    color: #a83135;
}

/* Min width 768 */
@media only screen and (min-width: 768px) {
    .content-container .description {
        font-size: 14px;
        line-height: 30px;
    }
}
.news-content-container .main-news {
    position: relative;
}

.news-content-container .main-news img {
    max-width: 100%;
}

.news-content-container .main-news .position-absolute {
    top: calc(50% - 120px)
}

.news-content-container .main-news .title-card {
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px 30px;
    position: relative;
    height: 237px;
}

.news-content-container .main-news .title-card .date {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #b0abab;
}

.news-content-container .main-news .title-card .title {
    font-weight: bold;
    font-size: 30px;
    color: #000;
}

.news-content-container .main-news .title-card .unes-btn {
    position: absolute;
    right: 30px;
    bottom: -20px;
}


.news-card {
    height: 345px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
}

.news-card .news-detail-info {
    background-color: rgba(0, 0, 0, 0.35);
    padding: 10px 20px;
}

.news-card .news-detail-info .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    margin-bottom: 0;
}

.news-card .news-detail-info .date {
    font-family: Roboto;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    color: #b0abab;
    margin-bottom: 5px;
}

/* Max width 768px */
@media only screen and (min-width: 768px) {
    .news-content-container .main-news img {
        max-width: 60%;
    }

    .news-content-container .main-news .title-card .title {
        font-size: 26px;
    }
}
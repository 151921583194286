.profile-content-container .profile img {
    width: 100%;
    object-fit: cover;
}

.profile-content-container .profile-purpose-card {
    border-radius: 20px;
    background: #f3f3f3;
    padding: 20px;
    height: 100%;
}

.profile-content-container .profile-purpose-card img {
    height: 75px;
    width: 75px;
    margin-bottom: 10px;
}

.profile-content-container .profile-purpose-card h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
    text-align: left;
    color: #000;
}

.profile-content-container .profile-purpose-card p,
.profile-content-container .profile-purpose-card li {
    font-family: Roboto;
    font-weight: normal;
    font-size: 15px;
    text-align: left;
    color: #000;
}

.profile-content-container .profile-purpose-card ul {
    list-style: circle;
}